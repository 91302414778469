import React from 'react';
import {
  layerType,
  mapConfigHandler,
  getColorBarTicks,
} from '../../../utils/colormaps';
import { config } from '../../../config';
import { store } from '../../../stores/app-state/store';

function createDefaultConfig(defaultConfig, fileMetadata) {
  if (defaultConfig?.minRange === undefined) {
    defaultConfig['minRange'] = fileMetadata.statistics.stats_min;
  }
  if (defaultConfig?.maxRange === undefined) {
    defaultConfig['maxRange'] = fileMetadata.statistics.stats_max;
  }
  if (
    defaultConfig?.minRange !== undefined &&
    defaultConfig?.maxRange !== undefined
  ) {
    defaultConfig['ticks'] =
      defaultConfig?.ticks ??
      getColorBarTicks(defaultConfig['minRange'], defaultConfig['maxRange']);
  }

  return defaultConfig;
}

export function getProductfileConfigOptions(
  productId,
  productfile,
  rasterOptions,
  setRasterOptions,
) {
  const {
    id: fileId,
    product_slug: fileSlug,
    product_id: fileProductId,
    metadata: fileMetadata,
  } = productfile;

  const defaultConfig = mapConfigHandler(fileSlug);
  let overrideConfig = Object.create(defaultConfig);

  if (
    defaultConfig.type === layerType.Continuous &&
    fileProductId === productId
  ) {
    if (rasterOptions?.has(fileProductId)) {
      const selectedOption = rasterOptions.get(fileProductId);
      if (selectedOption.override) {
        if (
          defaultConfig.minRange === selectedOption.override.minRange &&
          defaultConfig.maxRange === selectedOption.override.maxRange
        ) {
          // overrides are original values, reset rasterOption
          const _rasterOptions = new Map(rasterOptions);
          _rasterOptions.set(fileProductId, {
            defaultConfig: createDefaultConfig(defaultConfig, fileMetadata),
            statistics: fileMetadata.statistics,
            productFileId: fileId,
          });
          setRasterOptions(_rasterOptions);

          return defaultConfig;
        } else {
          overrideConfig.minRange = selectedOption.override.minRange;
          overrideConfig.maxRange = selectedOption.override.maxRange;
          overrideConfig.ticks = selectedOption.override.ticks;
          overrideConfig.colors = defaultConfig.colors;

          // passes the new file statistics keeping overrides in rasterOption
          if (
            selectedOption.productFileId !== fileId &&
            !defaultConfig.productSet
          ) {
            const _rasterOptions = new Map(rasterOptions);
            _rasterOptions.set(fileProductId, {
              defaultConfig: createDefaultConfig(defaultConfig, fileMetadata),
              override: overrideConfig,
              statistics: fileMetadata.statistics,
              productFileId: fileId,
            });
            setRasterOptions(_rasterOptions);
          }

          return overrideConfig;
        }
      }
    } else {
      const _rasterOptions = new Map(rasterOptions);

      _rasterOptions.set(fileProductId, {
        defaultConfig: createDefaultConfig(defaultConfig, fileMetadata),
        statistics: fileMetadata.statistics,
        productFileId: fileId,
      });
      setRasterOptions(_rasterOptions);

      return defaultConfig;
    }
  }

  return defaultConfig;
}

export function useRasterLayersSettings({
  productId,
  productfiles,
  isLoading,
}) {
  const { rasterOptionMap, doUpdateRasterOptionMap } =
    store.useRasterOptionMap();

  const [rasterLayerProps, setRasterLayerProps] = React.useState([]);

  React.useLayoutEffect(() => {
    const _rasterLayerProps =
      !isLoading && productfiles && productfiles?.length
        ? productfiles.map(pf => {
            let { id, product_slug: slug } = pf;

            const url = id ? `${config.API_ROOT}/product/file/${id}` : null;
            return url
              ? {
                  name: slug,
                  urls: [url],
                  slug,
                  id,
                  ...getProductfileConfigOptions(
                    productId,
                    pf,
                    rasterOptionMap,
                    doUpdateRasterOptionMap,
                  ),
                }
              : {};
          })
        : [];
    setRasterLayerProps(_rasterLayerProps);
  }, [
    JSON.stringify(productfiles),
    productId,
    rasterOptionMap,
    doUpdateRasterOptionMap,
    isLoading,
  ]);

  return rasterLayerProps;
}
