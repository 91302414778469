import React, { useEffect } from 'react';

import SelectProductCard from '../shared-components/selectProductCard';
import SelectWatershedCard from './dashboard-cards/selectWatershedCard';
import WatershedStatsCard from './dashboard-cards/watershedStatsCard';
import OlMap from './olMap';
import Sidebar from '../../app-components/sidebar';

import { toast } from 'react-toastify';

import {
  useGetWatershedStatsQuery,
  useGetMyWatershedSelectionQuery,
  useGetMyWatershedStatsQuery,
  useGetWCSDescribeCoverageQuery,
} from '../../stores/server-state/watersheds';
import { store } from '../../stores/app-state/store';
import Card from '../../app-components/card/card';
import { watershedStatsDesc } from '../HomePage';

const productSlug = 'swe-nd';

const WatershedStatistics = () => {
  const { watershedId } = store.useWatershedId();
  const { watershedName } = store.useWatershedName();
  const { myWatershedId } = store.useMyWatershedId();
  const { dateSelection } = store.useDateSelection();
  const { spatialResolution } = store.useSpatialResolution();
  const { isWatershedStatsLoading } = store.useIsWatershedStatsLoading();
  const { isSavePromptWatershed } = store.useIsSavePromptWatershed();
  const { isMyWatersheds, doUpdateIsMyWatersheds } = store.useIsMyWatersheds();
  const { doUpdateProductId } = store.useProductId();
  const { doUpdateIsSavePromptWatershed } = store.useIsSavePromptWatershed();
  const { isDrawingWatershed, doUpdateIsDrawingWatershed } =
    store.useIsDrawingWatershed();
  const { isDeletePromptWatershed, doUpdateIsDeletePromptWatershed } =
    store.useIsDeletePromptWatershed();

  const { isSuccess: wcsSuccess, isLoading } = useGetWCSDescribeCoverageQuery();

  const { data: myWatershedSelection } = useGetMyWatershedSelectionQuery({
    myWatershedId,
  });
  const { data: watershedStats } = useGetWatershedStatsQuery({
    watershedId,
    spatialResolution,
    isMyWatersheds,
  });
  const { data: myWatershedStats } = useGetMyWatershedStatsQuery({
    myWatershedId,
    spatialResolution,
    isMyWatersheds,
  });

  const statsData = isMyWatersheds
    ? myWatershedStats?.records ?? []
    : watershedStats?.records ?? [];

  const toggleWatershedsHandler = value => {
    doUpdateIsMyWatersheds(value === 'My Watersheds');
    isDrawingWatershed && doUpdateIsDrawingWatershed(false);
  };

  const getHybasId = () => {
    return watershedName
      ? watershedName.substring(0, watershedName.length - 3)
      : undefined;
  };

  const getFeatureName = () => {
    if (!isMyWatersheds) {
      const hybasId = getHybasId();
      return hybasId
        ? `HyBasID ${hybasId.substring(0, hybasId.length - 3)}`
        : 'Select a feature on the map...';
    } else return myWatershedSelection?.name ?? '';
  };

  useEffect(() => {
    if (!isMyWatersheds) doUpdateIsSavePromptWatershed(false);
  }, [isMyWatersheds, doUpdateIsSavePromptWatershed]);

  useEffect(() => {
    return () => {
      doUpdateProductId('');
      doUpdateIsSavePromptWatershed(false);
      doUpdateIsDeletePromptWatershed(false);
      doUpdateIsDrawingWatershed(false);
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let toastId;
      if (!wcsSuccess) {
        toastId = toast.error(
          'External WCS Service not Responding. Try again later.',
          {
            toastId: 1,
            autoClose: false,
          },
        );
      }

      if (wcsSuccess) {
        if (toastId) {
          toast.dismiss(toastId);
        }
      }
    }
  }, [isLoading, wcsSuccess]);

  return (
    <div>
      <Sidebar>
        <Card className='font-weight-light'>
          <span>{watershedStatsDesc}</span>
        </Card>
        <SelectProductCard defaultProductSlug={productSlug} />
        <SelectWatershedCard
          savePrompt={isSavePromptWatershed}
          deletePrompt={isDeletePromptWatershed}
          isMyWatersheds={isMyWatersheds}
          onToggleWatersheds={toggleWatershedsHandler}
          selectedFeatureId={getHybasId()}
        />
        <WatershedStatsCard
          data={statsData}
          spatialResolution={spatialResolution}
          isLoading={
            !!myWatershedId?.length &&
            !!spatialResolution?.length &&
            isWatershedStatsLoading
          }
          selectedFeatureName={getFeatureName()}
          selectedDate={dateSelection}
        />
      </Sidebar>
      <OlMap productSlug={productSlug} />
    </div>
  );
};

export default WatershedStatistics;
