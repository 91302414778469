import { gen } from './helpers';

let yesterdayDayOfMonth = new Date().getDate() - 2;
const yesterday = new Date();
yesterday.setDate(yesterdayDayOfMonth);

export const initialDate = yesterday.toISOString().split('T')[0];

export const createProductSlice = set => ({
  ...gen(set, {
    productId: '',
    productFileId: '',
    spatialResolution: '',
    dateSelection: '2024-01-01',
  }),
});
