import { useEffect } from 'react';
import { useOLMap } from '../../olMap';

import LayerGroup from 'ol/layer/Group';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import XYZ from 'ol/source/XYZ';
import { TileGrid } from 'ol/tilegrid';

// res = (extent[0] - extent[2]) / tileSize
// subsequent resoultions are res /= 2
export const sweWcsTileGrid = new TileGrid({
  extent: [-9000000.0, -9000000.0, 9000000.0, 9000000.0],
  resolutions: [
    70312.5, 35156.25, 17578.125, 8789.0625, 4394.53125, 2197.265625,
  ],
  tileSize: 512,
});

const baseMapLayersXYZ = [
  {
    id: 'CartoDBPositron',
    name: 'CartoDB Positron',
    url: 'https://cartodb-basemaps-{a-c}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
    attributions:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://cartodb.com/attributionss">CartoDB</a>',
    maxZoom: 19,
  },
  {
    id: 'CartoDBDarkMatter',
    name: 'CartoDB Dark Matter',
    url: 'https://cartodb-basemaps-{a-c}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
    attributions:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://cartodb.com/attributionss">CartoDB</a>',
    maxZoom: 19,
  },
  {
    id: 'OpenStreetMap',
    name: 'OpenStreetMap',
    url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    maxZoom: 19,
    attributions:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    id: 'EsriWorldStreetMap',
    name: 'ESRI Streetmap',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
    attributions:
      'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
  },
  {
    id: 'EsriWorldImagery',
    name: 'ESRI World Imagery',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attributions:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
  {
    id: 'EsriUSATopoMaps',
    name: 'ESRI Topo Maps',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer/tile/{z}/{y}/{x}',
    attributions:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
  {
    id: 'EsriWorldShadedRelief',
    name: 'ESRI World Hillshade',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}',
    attributions:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
  {
    id: 'EsriWorldOcean',
    name: 'ESRI World Ocean',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}',
    attributions:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
  {
    id: 'USGSImageryTopo',
    name: 'USGS Imagery/Topo',
    url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}',
    attributions:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    visible: true,
  },
];

const BaseLayerGroup = () => {
  const { map } = useOLMap();

  useEffect(() => {
    // Layer Groups
    const baseLayers = new LayerGroup({
      title: 'Base Maps',
      layers: [
        ...baseMapLayersXYZ.map(b => {
          return new WebGLTileLayer({
            title: b.name,
            type: 'base',
            visible: b.visible ?? false,
            source: new XYZ({
              url: b.url,
              crossOrigin: true,
              attributions: b.attributions,
              maxZoom: b.maxZoom,
            }),
          });
        }),
      ],
    });

    map.addLayer(baseLayers);
    return () => map.removeLayer(baseLayers);
  }, [map]);

  return null;
};

export default BaseLayerGroup;
