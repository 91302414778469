import React, { forwardRef, useEffect, useState, useMemo } from 'react';
import LineChartWrapper from '../../../../../../app-components/line-chart-wrapper';

import { doyToAbstractDate, dateToDoy } from '../../../../../../utils/helpers';

import './zonalStatsLineChart.scss';

const legendHandler = (_, legendItem, legend) => {
  const index = legendItem.datasetIndex;
  const ci = legend.chart;
  if (index !== 4 && index !== 6) {
    // default legend click handler
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
  } else {
    [ci.getDatasetMeta(index), ci.getDatasetMeta(index - 1)].forEach(meta => {
      meta.hidden =
        meta.hidden === null ? !ci.data.datasets[index].hidden : null;
    });
    ci.update();
  }
};

const ZonalStatsLineChart = forwardRef(
  (
    { data, selectedDate = '', waterYear, isHidden = false, plugins = [] },
    ref,
  ) => {
    const [unitLabel, setUnitLabel] = useState('km³');
    const [isTooltipEnabled, setIsTooltipEnabled] = useState(true);
    const [isTooltipFrozen, setIsTooltipFrozen] = useState(false);
    const [tooltipEvents, setTooltipEvents] = useState([
      'click',
      'mousemove',
      'mouseout',
    ]);

    const currentSWE = useMemo(
      () => ({
        label: `WY${waterYear}`,
        backgroundColor: 'rgb(255, 0, 0)',
        borderColor: 'rgb(255, 0, 0)',
        data: data,
        parsing: {
          xAxisKey: 'doy',
          yAxisKey: 'this_year',
        },
        fill: false,
        tension: 0.1,
        borderWidth: 1.6,
      }),
      [data, waterYear],
    );

    const lastYearSWE = useMemo(
      () => ({
        label: `WY${waterYear - 1}`,
        backgroundColor: 'rgb(135, 95, 255)',
        borderColor: 'rgb(135, 95, 255)',
        data: data,
        parsing: {
          xAxisKey: 'doy',
          yAxisKey: 'last_year',
        },
        tension: 0.1,
        borderWidth: 1.6,
      }),
      [data, waterYear],
    );

    const avgSWE = useMemo(
      () => ({
        label: 'Mean',
        backgroundColor: 'rgb(0, 150, 0, 0.4)',
        borderColor: 'rgb(0, 150, 0)',
        borderDash: [2],
        data: data,
        parsing: {
          xAxisKey: 'doy',
          yAxisKey: 'hist_mean',
        },
        fill: false,
        tension: 0.1,
        borderWidth: 2,
      }),
      [data],
    );

    const minSWE = useMemo(
      () => ({
        label: 'Min/Max',
        backgroundColor: 'rgb(20, 0, 200, 0.2)',
        borderColor: 'rgb(20, 0, 200, 0.5)',
        fill: '-1',
        data: data,
        parsing: {
          xAxisKey: 'doy',
          yAxisKey: 'hist_min',
        },
        tension: 0.1,
        borderWidth: 1,
      }),
      [data],
    );

    const maxSWE = useMemo(
      () => ({
        label: '_Max', // hidden
        backgroundColor: 'rgb(20, 0, 200, 0.2)',
        borderColor: 'rgb(20, 0, 200, 0.5)',
        fill: false,
        data: data,
        parsing: {
          xAxisKey: 'doy',
          yAxisKey: 'hist_max',
        },
        tension: 0.1,
        borderWidth: 1,
      }),
      [data],
    );

    const stdDevMinSWE = useMemo(
      () => ({
        label: 'Std Dev',
        backgroundColor: 'rgb(100, 250, 250, 0.6)',
        fill: '-1',
        data: Array.from(data, row => row.hist_mean - row.hist_std),
        tension: 0.1,
        borderWidth: 1,
      }),
      [data],
    );

    const stdDevMaxSWE = useMemo(
      () => ({
        label: '_Std Dev', // hidden
        backgroundColor: 'rgb(100, 250, 250, 0.6)',
        fill: false,
        data: Array.from(data, row => row.hist_mean + row.hist_std),
        tension: 0.1,
        borderWidth: 1,
      }),
      [data],
    );

    const datasets = [
      currentSWE,
      lastYearSWE,
      avgSWE,
      stdDevMaxSWE,
      stdDevMinSWE,
      maxSWE,
      minSWE,
    ];

    // Std Dev as array for tooltip lookup
    const stdDevLookup = Array.from(data, row => row.hist_std);

    // <doy>: <dd-mmm>
    const xLabelLookup = new Map([
      [245, '01-Sep'],
      [275, '01-Oct'],
      [306, '01-Nov'],
      [336, '01-Dec'],
      [1, '01-Jan'],
      [32, '01-Feb'],
      [61, '01-Mar'],
      [92, '01-Apr'],
      [122, '01-May'],
      [153, '01-Jun'],
      [184, '01-Jul'],
      [214, '01-Aug'],
    ]);

    // <unit>: <multiplier>
    const unitConversionLookup = {
      'cm³': 1e11,
      'm³': 1e9,
      '10³ m³': 1e6,
      '10⁶ m³': 1000,
      'km³': 1,
      '10³ km³': 0.001,
      'values out of range': 0,
    };

    // Converts tooltip and tock values based on unit state
    const convertUnitValue = (value, label = unitLabel) => {
      value *= unitConversionLookup[label];
      return value.toFixed(2);
    };

    // Used for chart callback when y axis changes
    const getUnitValueRange = maxTick => {
      let label;
      if (maxTick <= 1e-10) {
        label = 'cm³';
      } else if (maxTick <= 1e-5) {
        label = 'm³';
      } else if (maxTick <= 0.001) {
        label = '10³ m³';
      } else if (maxTick <= 1) {
        label = '10⁶ m³';
      } else if (maxTick <= 1000) {
        label = 'km³';
      } else if (maxTick <= 1e6) {
        label = '10³ km³';
      } else {
        label = 'values out of range';
      }
      setUnitLabel(label);
      return label;
    };

    const waterYearHandler = date => {
      let x = dateToDoy(date);
      if (x > 365) x = 1;
      x += 121;
      if (x >= 365) x -= 365;
      return x;
    };

    const options = {
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
      plugins: {
        ...plugins,
        verticalLine: {
          label: selectedDate.length
            ? doyToAbstractDate(dateToDoy(selectedDate))
            : '',
          indexArray: selectedDate.length
            ? [waterYearHandler(selectedDate)]
            : [],
        },
        legend: {
          labels: {
            filter: (item, _) => !item.text.includes('_'),
          },
          onClick: legendHandler,
        },
        tooltip: {
          animation: { duration: 1000 },
          enabled: isTooltipEnabled,
          events: tooltipEvents,
          position: 'nearest',
          caretPadding: 0,
          caretSize: 13,
          callbacks: {
            title: context => {
              const title = context[0].label || '';
              let formattedDate = '';
              if (title) {
                formattedDate = doyToAbstractDate(title);
              }
              return `Click to freeze / unfreeze tooltip\n\nDate: ${formattedDate}\nCumulative DOY: ${title}`;
            },
            label: context => {
              let label = context.dataset.label || '';
              let value = 0;
              if (label) {
                if (label === '_Std Dev') {
                  return '';
                }
                // Min/Max acts as an alias for Min to allow toggling both
                // Min and Max datasets with the legend onClick
                if (label === 'Min/Max') {
                  label = 'Min';
                }
                if (label === '_Max') {
                  label = 'Max';
                }
              }

              if (!context.parsed.y) {
                value = '0.00';
              } else {
                if (label.includes('Std Dev')) {
                  value = `±${convertUnitValue(
                    stdDevLookup[context.dataIndex],
                  )}`;
                } else {
                  value = convertUnitValue(context.parsed.y);
                }
              }
              // The callback here happens on tooltip events
              // so label and value conversion will be in sync with the state
              return `${label}:  ${value}  ${unitLabel}`;
            },
          },
        },
      },
      onClick: () => {
        setIsTooltipFrozen(!isTooltipFrozen);
      },
      onHover: () => {
        !isTooltipEnabled && setIsTooltipEnabled(true);
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0,
          hitRadius: 5,
        },
      },
      interaction: {
        intersect: false,
        axis: 'x',
        mode: 'index',
      },
      scales: {
        y: {
          min: 0,
          title: {
            display: true,
            text: `SWE  (${unitLabel})`,
          },
          ticks: {
            callback: (value, _, values) => {
              // get last element of y axis
              const maxTick = values.slice(-1)[0];
              const label = getUnitValueRange(maxTick.value);
              return parseFloat(convertUnitValue(value, label));
            },
          },
        },
        x: {
          ticks: {
            // x is the index of each tick (0 - 364)
            callback: x => {
              x += 244;
              if (x > 365) x -= 365;
              return xLabelLookup.get(x); // undefined ticks do not appear
            },
          },
        },
      },
    };

    useEffect(() => {
      if (isTooltipFrozen) {
        setIsTooltipFrozen(false);
        setIsTooltipEnabled(false);
      }
    }, [data]);

    useEffect(() => {
      isTooltipFrozen
        ? setTooltipEvents(['click'])
        : setTooltipEvents(['click', 'mousemove', 'mouseout']);
    }, [data, isTooltipFrozen, setTooltipEvents]);

    return (
      <LineChartWrapper
        className='line-chart'
        datasets={datasets}
        options={options}
        isHidden={isHidden}
        ref={ref}
      />
    );
  },
);

export default ZonalStatsLineChart;
